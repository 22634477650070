<template>
  <div class="uk-section successful">
    <div class="uk-container uk-container-large">
      <!-- <ul uk-tab class="uk-child-width-expand">
        <template v-for="(item, index) in tabs">
          <li :class="{ 'uk-active': index === tabIndex }" :key="index">
            <a href="#">{{ item.label }}</a>
          </li>
        </template>
      </ul> -->
      <div >
        <div uk-grid class="uk-child-width-1-4">
          <div v-for="(item, index) in list" :key="index">
            <div class="successful-item">
              <img class="successful-item-img" :src="item.img" />
              <div class="successful-item-name">{{ item.name }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tabIndex: 0,
      tabs: [
        {
          label: "全部类目",
          value: "",
        },
        {
          label: "旅行社",
          value: "",
        },
        {
          label: "本地生活",
          value: "",
        },
        {
          label: "景区景点",
          value: "",
        },
        {
          label: "酒店/民宿",
          value: "",
        },
        {
          label: "研学亲子游",
          value: "",
        },
        {
          label: "文旅集群",
          value: "",
        },
      ],
      list: [
        {
          img: require("@/assets/32461704184196_.pic.jpg"),
          name: "中国旅游集团",
        },
        {
          img: require("@/assets/32471704184217_.pic.jpg"),
          name: "花间堂",
        },
        {
          img: require("@/assets/32481704184225_.pic.jpg"),
          name: "西岭雪山",
        },
        {
          img: require("@/assets/32491704184232_.pic.jpg"),
          name: "联联周边游",
        },
        {
          img: require("@/assets/32501704184238_.pic.jpg"),
          name: "四川青旅",
        },
        {
          img: require("@/assets/32511704184245_.pic.jpg"),
          name: "东门市井",
        },
        {
          img: require("@/assets/32521704184253_.pic.jpg"),
          name: "环球国旅",
        },
        {
          img: require("@/assets/32531704184266_.pic.jpg"),
          name: "嗨鸭旅行",
        }
      ],
    };
  },
};
</script>

<style lang="less">
.successful {
  .uk-tab {
    > * {
      > a {
        color: #333;
        padding-bottom: 32px;
        display: block;
        text-align: center;
      }
    }
    > .uk-active {
      > a {
        color: @color;
        position: relative;
        &::after {
          position: absolute;
          width: 55px;
          height: 5px;
          background: @color;
          content: "";
          display: block;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
        }
      }
    }
  }
  &-item {
    padding: 28px;
    background: #fff;
    text-align: center;
    box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.07);
    border-radius: 10px;
    cursor: pointer;
    &-img {
      height: 260px;
      display: block;
      margin-bottom: 40px;
    }
    &-name {
      font-size: 26px;
      color: #333333;
    }
  }
}
</style>