<template>
  <div uk-slideshow="ratio:1920:900;autoplay: true;finite: true">
    <ul class="uk-slideshow-items">
      <li @click="showQr">
        <img uk-cover src="@/assets/f0b28108-8b2b-09b7-f848-f9ae78519540.png" />
      </li>
    </ul>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>