<template>
  <div>
    <!-- 楼层一 -->
    <floor-1 />
    <!-- 楼层一 -->
    <floor-2 />
  </div>
</template>

<script>
import floor1 from "./components/floor1.vue";
import floor2 from "./components/floor2.vue";
export default {
  components: {
    floor1,
    floor2
  },
};
</script>

<style>
</style>